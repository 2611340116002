@import '../base/variables';

.leaderboard-summary {
  margin: 0 auto;
  background: $white;
  user-select: none;
  .apy-highlight {
    display: flex;
    align-items: center;
    .mobile & {
      .icon-view-detail {
        display: none;
      }
    }
    .apy-info {
      text-transform: uppercase;
      text-align: right;
      white-space: nowrap;
      &-highlight {
        color: $green;
        font-weight: bold;
      }
    }
  }
  .icon-view-detail {
    margin: -12px -8px;
    padding: 16px;
    float: right;
    color: $text;
    font-weight: bold;
  }
  th {
    text-transform: uppercase;
  }
  tr {
    cursor: pointer;
  }
  tr:last-child td {
    border: none;
  }
  .expandable-row {
    display: flex;
    justify-content: space-between;
  }
}

.leaderboard-summary,
.leaderboard-panel {
  .pre-staking {
    color: $cyan;
  }
  .coming-soon {
    color: $dark-gray;
  }
  .live {
    color: $green;
  }
}

.leaderboard-page {
  .leaderboard-panel {
    margin-bottom: 16px;
    width: 100%;
    border-radius: 8px;
    color: $text-light;
    .heading-img {
      margin-right: 8px;
      width: 30px;
    }
    .panel-item {
      padding: 16px 0;
      &:first-child {
        padding-top: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $light-gray;
      }
    }
    .apy-info {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      &-item {
        text-transform: uppercase;
        .apy-rate {
          color: $green;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .ant-card-head {
      color: $text;
    }
  }

  .leaderboard-table {
    background: $white;
    th.ant-table-cell {
      white-space: nowrap;
    }
    td {
      font-size: 12px;
      font-weight: bold;
    }
    .ranking {
      position: relative;
      z-index: 1;
      + img {
        position: absolute;
        transform: translate(-15px, -25px);
      }
    }
    .ranking-bitmax {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #124495;
      img {
        padding: 1px;
        width: 22px;
        background: $white;
        border-radius: 50%;
      }
    }
    .address {
      display: inline-block;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .row-dao-announcement {
    padding: 16px 16px 4px 26px;
    background: rgba(10, 7, 82, 0.48);
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .dao-link {
    cursor: pointer;
    margin-left: 4px;
    color: lightskyblue;
  }

  .leaderboard-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 16px 96px 16px 32px;
    background: rgba(10, 7, 82, 0.48);
    border-radius: 8px;
    user-select: none;
    .heading-item {
      .heading-number {
        font-size: 24px;
        font-weight: bold;
      }
      small {
        color: $gray;
      }
    }
  }
}

.mobile {
  .leaderboard-page {
    padding: 0 0 32px;
    .ant-col {
      width: 100%;
    }
    .leaderboard-heading {
      flex-direction: column;
      padding: 16px;
    }
  }
}
