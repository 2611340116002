@import '../base/variables';
@import '../base/mixin';

@keyframes rocket-movement {
  100% {
    transform: translate(120rem, -60rem);
  }
}
@keyframes move-astronaut {
  100% {
    transform: translate(-16rem, -16rem);
  }
}
@keyframes rotate-astronaut {
  100% {
    transform: rotate(-720deg);
  }
}
@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.not-found-page {
  &:before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
  }
  .content-404-wrapper {
    position: relative;
    padding: 100px 10px;
    text-align: center;
    z-index: 1000;
    animation-delay: .6s;
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    h1 {
      color: $text;
    }
    .btn-go-home {
      padding: 10px 20px;
      font-weight: bold;
      background: $primary;
      color: $text;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 20px;
      border-radius: 10rem;
      text-decoration: none;
      transition: all 0.3s ease-in 0s;
    }
  }
  .objects {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .object-rocket {
      z-index: 95;
      position: absolute;
      transform: translateX(-50px);
      top: 75%;
      pointer-events: none;
      height: 64px;
      animation: 200s linear 0s infinite normal both running rocket-movement;
    }
    .object-earth {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 90;
      width: 10rem;
    }
    .object-moon {
      position: absolute;
      top: -30%;
      left: 20%;
      width: 5rem;
    }
    .box-astronaut {
      position: absolute;
      top: 80%;
      right: 10%;
      will-change: transform;
      z-index: 110 !important;
      animation: 50s linear 0s infinite alternate both running move-astronaut;
      .object-astronaut {
        width: 10rem;
        animation: 200s linear 0s infinite alternate both running rotate-astronaut;
      }
    }
  }
}

.mobile .not-found-page {
  .content-404-wrapper {
    padding: 10px;
    .image-404 {
      width: 100%;
    }
  }
  .objects {
    .object-earth,
    .object-moon {
      display: none;
    }
  }
}
