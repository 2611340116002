.stake-withdraw-form {
  .ant-legacy-form-item {
    >* {
      width: 100%;

    }
    label {
      padding: 16px 0;
      color: #9795c5;
      &:before {
        display: none;
      }
    }

    .ant-legacy-form-item-children {
      display: block;
      padding-bottom: 30px;
      + .ant-legacy-form-explain {
        margin-top: -20px;
        height: 20px;
        span[role="img"] {
          margin-right: 5px;
        }
      }
    }
    
  }
  .form-info {
    padding: 16px 0;
    color: #9795c5;
    user-select: none;
    &.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.form-qr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .action {
        display: flex;
        margin-left: 8px;
        .btn-action {
          display: inline-block;
          position: relative;
          margin-right: 4px;
          padding: 0 4px;
          min-width: 32px;
          line-height: 24px;
          background: $white;
          color: $black;
          text-align: center;
          border-radius: 12px;
          &:hover {
            .qr-popup {
              display: block;
            }
          }
          > img {
            margin-top: -2px;
            width: 16px;
            height: 16px;
          }
        }
        .btn-action-qr {
          width: 36px;
          height: 24px;
          background-size: 20px;
          background-repeat: no-repeat;
          background-position: center;
        }
        .qr-popup {
          display: none;
          position: absolute;
          top: -140px;
          left: 0;
          width: 120px;
          height: 120px;
          background: $white;
          border-radius: 8px;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 20%;
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-top-color: $white;
            border-bottom: 0;
            border-left: 0;
            margin-left: -8px;
            margin-bottom: -16px;
          }
          .qr-code {
            padding: 14px;
            background: $white;
            border-radius: 8px;
          }
        }
      }
      .qr-text {
        flex: 1;
        text-align: right;
        user-select: text;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .title {
      margin-top: 32px;
      font-size: 28px;
      color: $white;
    }
    .content {
      font-size: 18px;
    }
    small {
      color: #6e66a0;
    }
    select {
      background: transparent;
      border: none;
      outline: none;
    }
    .selectable {
      padding: 0;
      background: transparent;
      color: #9795c5 !important;
      text-align: right;
    }
    .ant-checkbox + span {
      color: #9795c5 !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: $primary;
      color: #9795c5 !important;
      border-color: $primary;
    }
  }
  .ant-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    input,
    .ant-input-group-addon {
      background: transparent !important;
      color: #9795c5 !important;
      border: none;
      box-shadow: none !important;
    }
    input {
      flex: 1;
      padding: 0;
      border-radius: 0;
      font-size: 20px;
      font-weight: bold;
    }
    .ant-input-group-addon {
      padding: 0;
      width: auto;
    }
  }
}

.mobile {
  .stake-withdraw-form {
    .form-info {
      padding: 12px 0;
      &.form-qr {
        flex-wrap: wrap;
        .qr-text {
          order: 3;
          flex-basis: 100%;
          margin-top: 4px;
          padding: 0;
          text-align: left;
        }
        .qr-popup {
          left: -40px;
          &:after {
            left: calc(20% + 40px);
          }
        }
      }
    }
  }
}
