@import '../base/variables';
@import '../base/mixin';

.app {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  background-image: url('../../images/bg-pattern.svg'), linear-gradient(104.55deg, #413d8e 30.91%, #87b6f3 90.88%);
  background-attachment: fixed;
  background-size: auto;
  background-color: #413d8e;
  color: $white;
  .logo {
    height: 25px;
  }
  .navbar {
    margin-bottom: 24px;
    padding: 20px 80px;
    z-index: 100;
    &-content {
      display: flex;
      align-items: flex-start;
      height: 100%;
      .wallet-address {
        display: flex;
        flex-wrap: wrap;
        margin-left: 40px;
        margin-right: 10px;
        width: 400px;
        > input {
          flex: 1;
          padding: 0 5px 0 15px;
          border-radius: 24px 0 0 24px;
          font-size: 12px;
        }
        > a {
          width: 100px;
          border-radius: 0 24px 24px 0;
          white-space: nowrap;
          font-weight: normal;
        }
        > .warning-message {
          flex-basis: 100%;
          margin: 4px 16px 0;
          font-size: 14px;
        }
        .warning {
          color: $orange !important;
        }
      }
      .navigation-link {
        margin-top: 8px;
        line-height: 20px;
        color: $white;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 2px solid $white;
      }
    }
  }

  .footer {
    font-family: 'Avenir' !important;
    margin-top: 24px;
    padding: 20px 80px;
    background: rgba(0, 0, 0, 0.12);
    font-size: 10px;
    font-weight: 300;
    .footer-nav {
      display: flex;
      .footer-item {
        margin-right: 40px;
        color: inherit;
      }
    }
    .footer-content {
      display: flex;
      justify-content: space-between;
      margin-top: 48px;
      margin-bottom: 16px;
      .social-list {
        > a {
          margin-right: 24px;
          &:last-child {
            margin: 0;
          }
        }
        .social-item {
          height: 20px;
        }
      }
      .netselect {
        position: absolute;
        bottom: 20px;
        right: 80px;
        height: 24px;
        padding: 0 24px 0 8px;
        outline: none;
        border: none;
        border-radius: 4px;
        appearance: none;
        background-color: $white;
        color: $text;
        background-image: linear-gradient(45deg, transparent 50%, #333 50%),
          linear-gradient(135deg, #333 50%, transparent 50%);
        background-position: calc(100% - 12px) 10px, calc(100% - 7px) 10px;
        background-size: 5px 5px, 5px 5px;
        background-repeat: no-repeat;
      }
    }
  }

  > .content {
    flex: 1;
  }
}

.mobile {
  .navbar {
    padding: 20px;
    text-align: center;
    &-content {
      align-items: center;
      flex-direction: column;
      .wallet-address {
        margin: 20px 0 0;
        width: 100%;
        > input {
          width: 100%;
          font-size: 11px;
        }
        > a {
          width: 78px;
          padding: 8px;
        }
      }
      .navigation-link {
        margin-top: 10px;
      }
    }
  }
  .footer {
    padding: 20px;
    text-align: center;
    .footer-nav {
      flex-wrap: wrap;
      .footer-item {
        flex-basis: 50%;
        margin: 0;
        padding: 15px 10px;
      }
    }
    .footer-content {
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      .social-list {
        margin: 8px 0;
      }
      .netselect {
        position: unset;
        margin-top: 16px;
      }
    }
  }
  > .content {
    padding: 0 10px;
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #413d8e;
  z-index: 100;
  transition: all 0.3s;
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}
