@mixin mw($len) {
  margin-left: auto;
  margin-right: auto;
  max-width: #{$len}px;
}

@mixin spinner-size($size) {
  width: $size;
  height: $size;
  border-width: $size / 6;
}
