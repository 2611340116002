@import '~antd/dist/antd';

@import '../components/layout';
@import '../components/table';
@import '../components/button';
@import '../components/modal';
@import '../components/form';
@import '../components/language-switcher';
@import '../vendor/spinkit.css';

.loading-anim {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(7, 5, 62, 0.75);
  z-index: 1;
  .dots {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .dot {
    margin: 0 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #00ffd1;
    opacity: 0.3;
    transition: all 0.2s;
    &.previous {
      opacity: 0.6;
    }
    &.current {
      opacity: 1;
    }
  }
}

.toast-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 !important;
  font-size: 14px;
  .desktop & {
    width: 480px !important;
    max-width: 100%;
    transform: translateX(-50%) !important;
  }
  .Toastify__toast--default {
    display: inline-flex;
    margin-bottom: 12px;
    padding: 4px 8px;
    max-width: calc(100% - 48px);
    min-height: unset;
    background: transparentize($color: $black, $amount: 0.4);
    color: $white;
    border-radius: 8px;
    .Toastify__toast-body {
      margin-right: 32px;
    }
  }
  .Toastify__close-button--default {
    color: $white;
  }
}
