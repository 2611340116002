@import '../base/variables';
@import '../base/mixin';

.spinner-wrapper {
  --sk-size: 30px;
  --sk-color: #{$cyan};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  text-align: center;
  .lozi {
    @include spinner-size(30px);
    display: inline-flex;
    border: 5px solid rgba(170, 0, 0, 0.2);
    border-left: 5px solid $red;
    border-radius: 50%;
    transform: translateZ(0);
    animation: spin 1.1s infinite linear;
  }
  .sk-plane,
  .sk-chase,
  .sk-bounce,
  .sk-wave,
  .sk-pulse,
  .sk-flow,
  .sk-circle,
  .sk-circle-fade,
  .sk-wander {
    display: inline-flex;
    transform: translateZ(0);
  }
  .sk-swing {
    display: inline-flex;
  }
  .sk-grid,
  .sk-fold {
    display: inline-block;
    transform: translateZ(0);
  }
  .sk-wave {
    --sk-size: 40px;
    --sk-color: #{transparentize($cyan, 0.3)};
    &-rect {
      flex: 1;
      margin: 0 1px;
      border-radius: 4px;
    }
  }
  .sk-flow {
    --sk-color: #{transparentize($cyan, 0.3)};
    align-items: center;
  }
}
