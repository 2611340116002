.navbar-logout-modal {
  .ant-modal {
    text-align: center;
    &-content {
      padding: 16px 64px;
      border-radius: 16px;
      overflow: hidden;
    }
    &-header,
    &-footer {
      border: none;
    }
    &-body {
      padding: 0;
    }
    &-title {
      color: $text !important;
      font-weight: bold;
    }
    &-footer {
      > div {
        display: flex;
        flex-direction: column-reverse;
      }
      button {
        display: block;
        width: 100%;
        margin: 0;
        padding: 8px;
        height: unset;
        background: $white;
        color: $text !important;
        border: 1px solid $primary;
        border-radius: 16px;
        + button {
          margin-bottom: 16px;
          background: $primary;
        }
      }
    }
  }
}

.stake-withdraw-modal {
  &.success {
    .ant-modal {
      &-body {
        padding: 20px;
      }
    }
  }
  .ant-modal {
    width: 630px !important;
    &-content {
      background: #07053e;
      color: $white;
      border-radius: 8px;
    }
    &-title {
      color: inherit;
      h2 {
        display: flex;
        align-items: center;
      }
    }
    &-close {
      display: none;
    }
    &-header {
      padding: 40px 80px 0;
      background: initial;
      color: $white;
      border: none;
      user-select: none;
      img {
        width: 40px;
      }
    }
    &-body {
      padding: 20px 80px;
      .withdraw-form-select {
        user-select: text;
      }
    }
    &-footer {
      text-align: center;
      border-color: rgba(255, 255, 255, 0.1);
      button {
        width: 150px;
        margin: 16px 8px;
        padding: 8px;
        height: unset;
        background: rgba(255, 255, 255, 0.2);
        color: $white !important;
        border: none;
        border-radius: 16px;
        + button:not([disabled]) {
          background: $primary;
          color: $text !important;
        }
        + button[disabled] {
          background: $light-gray;
          color: $text !important;
        }
      }
    }
  }
  [class^='ant-form'] {
    color: $white;
  }
}

.stake-withdraw-modal.mobile {
  .ant-modal {
    &-header {
      padding: 40px 10px 0;
    }
    &-body {
      padding: 0 20px;
    }
    &-footer {
      button {
        margin: 8px;
      }
    }
  }
}
