@import '../base/variables';
@import '../base/mixin';

.home-page {
  h1 {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 200;
  }
  .link {
    font-weight: 700;
    color: $white;
    text-decoration: underline;
  }
  table {
    color: $text;
  }
}

.mobile {
  .home-page {
    h1 {
      font-size: 28px;
    }
  }
}
