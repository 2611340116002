
body {
  margin: 0;
  padding: 0;
  font-family: 'D-DIN' !important;
  font-size: 16px !important;
  box-sizing: border-box;
  background-color: #413d8e !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  height: 100%;
}

h1,
h2,
h3 {
  color: $white !important;
}

input {
  background: $white;
  color: $black !important;
  padding: 4px 8px;
  border: none;
  outline: none;
  border-radius: 6px;
}
