@import '../base/variables';
@import '../base/mixin';

.ant-table {
  font-size: 16px;
  border-radius: 8px;
  overflow: hidden;
  &-wrapper {
    border-radius: 8px;
    .ant-pagination {
      margin-right: 16px;
      color: $text !important;
      font-size: 16px;
      font-weight: bold;
      &-options,
      &-item,
      &-jump-prev,
      &-jump-next {
        display: none;
      }
      &-prev,
      &-next {
        a {
          .anticon {
            display: none;
          }
          &:before {
            content: '';
            display: inline-block;
            padding: 4px;
            border: solid $text;
            border-width: 0 3px 3px 0;
          }
        }
      }
      &-prev {
        a:before {
          -webkit-transform: rotate(135deg) translate(-1px, -1px);
          transform: rotate(135deg) translate(-1px, -1px);
        }
      }
      &-next {
        a:before {
          -webkit-transform: rotate(-45deg) translate(-1px, -1px);
          transform: rotate(-45deg) translate(-1px, -1px);
        }
      }
      &-disabled {
        a:before {
          opacity: 0.3;
        }
      }
      &:first-child {
        position: absolute;
        top: 16px;
        right: 0;
        z-index: 1;
      }
    }
  }
  &-title {
    margin-bottom: 1px;
    padding: 24px 32px;
    color: $text !important;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid $light-gray;
  }
  &-content {
    overflow: auto;
    > table {
      color: $text;
      th {
        padding: 16px 8px !important;
        background: $white !important;
        color: $text !important;
        font-size: 12px;
        font-weight: normal !important;
      }
      td {
        padding: 24px 8px !important;
      }
      th,
      td {
        &:only-child {
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
        &:not(:only-child) {
          &:first-child {
            padding-left: 32px !important;
          }
          &:last-child {
            padding-right: 64px !important;
          }
        }
      }
      img {
        width: 30px;
        height: auto;
      }
      a {
        color: $text;
      }
    }
  }
  &-row-expand-icon {
    .desktop & {
      display: none;
    }
  }
}

.mobile {
  .ant-table {
    .ant-table-content > table {
      th, td {
        &:not(:only-child):first-child {
          padding-left: 16px !important;
        }
        &:not(:only-child):last-child {
          padding-right: 16px !important;
          width: 100%;
        }
      }
    }
  }
}
