$bg-dark: #101921;
$bg-light: #2b3947;
$bg-ash: #48515d;
$text: #0f0f77;
$text-light: #56408B;

$primary: #00ffd1;

$red: #ee5366;
$green: #28d24e;
$orange: #ff6c6c;
$cyan: #5ddaf4;
$yellow: #f0b90b;
$black: #000;
$white: #fff;
$ash-gray: #f6f6f6;
$light-gray: #ebebeb;
$gray: #cbcbcb;
$dark-gray: #9c9c9c;
