@import '../base/variables';
@import '../base/mixin';

[class^='btn-'] {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  user-select: none;
}

.btn-fw {
  display: block;
}

.btn-primary {
  background: $primary;
  color: $text !important;
  border-radius: 16px;
  transition: all 0.4s;
  &.connected,
  &.cancel {
    background: rgba(255, 255, 255, 0.2);
    color: $white !important;
  }
  &.not-ready {
    background: $dark-gray;
  }
  &.withdraw {
    background: $white;
    border: 1px solid $primary;
  }
  &.lg {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 24px;
  }
  &:hover {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.12);
  }
}

.btn-back {
  display: inline-block;
  background: $light-gray;
  color: $black !important;
  border-radius: 16px;
}

.btn-light {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: $white;
  color: $text !important;
  > img {
    margin-right: 8px;
  }
}

.btn-green-light {
  display: block;
  background: $primary;
  color: $text !important;
}

.btn-gray {
  display: block;
  background: $dark-gray;
  color: $text !important;
}

.btn-withdraw {
  display: block;
  background: $white;
  color: $text !important;
  border: 1px solid $primary;
}
