@import '../base/variables';

.pull {
  &-left {
    float: left !important;
  }
  &-right {
    float: right !important;
  }
}

.text {
  &-left {
    text-align: left !important;
  }
  &-right {
    text-align: right !important;
  }
  &-center {
    text-align: center !important;
  }

  &-primary {
    color: $primary !important;
  }
  &-red {
    color: $red !important;
  }
  &-yellow {
    color: $yellow !important;
  }
  &-green {
    color: #44b848 !important;
  }

  &-italic {
    font-style: italic !important;
  }
  &-bold {
    font-weight: bold !important;
  }
  &-uppercase {
    text-transform: uppercase !important;
  }
}

.d {
  &-flex {
    display: flex !important;
  }
  &-none {
    display: none !important;
  }
}

.align {
  &-center {
    align-items: center !important;
  }
}

.flex {
  &-1 {
    flex: 1;
  }
}

$spacing-list: 0 4 8 16 32 48 64;
@each $x in $spacing-list {
  .mt-#{$x} {
    margin-top: #{$x}px !important;
  }
  .mb-#{$x} {
    margin-bottom: #{$x}px !important;
  }
  .ml-#{$x} {
    margin-left: #{$x}px !important;
  }
  .mr-#{$x} {
    margin-right: #{$x}px !important;
  }
  .mx-#{$x} {
    margin-left: #{$x}px !important;
    margin-right: #{$x}px !important;
  }
  .my-#{$x} {
    margin-top: #{$x}px !important;
    margin-bottom: #{$x}px !important;
  }
  .pt-#{$x} {
    padding-top: #{$x}px !important;
  }
  .pb-#{$x} {
    padding-bottom: #{$x}px !important;
  }
  .pl-#{$x} {
    padding-left: #{$x}px !important;
  }
  .pr-#{$x} {
    padding-right: #{$x}px !important;
  }
  .px-#{$x} {
    padding-left: #{$x}px !important;
    padding-right: #{$x}px !important;
  }
  .py-#{$x} {
    padding-top: #{$x}px !important;
    padding-bottom: #{$x}px !important;
  }
}

.pointer {
  cursor: pointer !important;
}
