@import '../base/variables';
@import '../base/mixin';

.language-switcher {
  position: relative;
  color: $white;
  cursor: pointer;
  &:hover {
    .language-list {
      display: block;
    }
  }
  .current {
    display: flex;
    align-items: center;
  }
  .language-list {
    display: none;
    position: absolute;
    bottom: 100%;
    padding-bottom: 10px;
    background: $white;
    color: $text;
    text-align: left;
    border-radius: 4px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    .language-item {
      padding: 10px;
      font-size: 12px;
      white-space: nowrap;
      &.selected {
        background: $ash-gray;
      }
      &.disabled,
      &:hover {
        background: $light-gray;
      }
    }
  }
}

.mobile {
  .language-switcher {
    margin: 0 auto;
    padding: 15px 10px;
  }
}
